import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  }, 
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'nuevovuelo',
    loadChildren: () => import('./nuevovuelo/nuevovuelo.module').then( m => m.NuevovueloPageModule)
  },
  {
    path: 'peakvuelos',
    loadChildren: () => import('./peakvuelos/peakvuelos.module').then( m => m.PeakvuelosPageModule)
  },
  {
    path: 'tiposaseo',
    loadChildren: () => import('./tiposaseo/tiposaseo.module').then( m => m.TiposaseoPageModule)
  },
  {
    path: 'colacion',
    loadChildren: () => import('./colacion/colacion.module').then( m => m.ColacionPageModule)
  },
  {
    path: 'inicioadmin',
    loadChildren: () => import('./inicioadmin/inicioadmin.module').then( m => m.InicioadminPageModule)
  },  {
    path: 'cartilla',
    loadChildren: () => import('./cartilla/cartilla.module').then( m => m.CartillaPageModule)
  },
  {
    path: 'observaciones',
    loadChildren: () => import('./observaciones/observaciones.module').then( m => m.ObservacionesPageModule)
  },
  {
    path: 'equipaje',
    loadChildren: () => import('./equipaje/equipaje.module').then( m => m.EquipajePageModule)
  }






  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
