import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { OfertasResponse } from  './ofertasresponse';
import { Observable, from, throwError,  BehaviorSubject  } from 'rxjs';
import { catchError, finalize, mergeMap, switchMap, filter, take, map, tap, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage'; 
@Injectable({
  providedIn: 'root'
})

 
export class ApiDataService {
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  SERVER_API_ADDRESS:  string  =  'http://192.241.211.179:4002'; 
  
  constructor(private  httpClient:  HttpClient,private storage:Storage) { }

   // Handle API errors
   handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
  getOperadores(): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/operadores')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }; 
  getOtrosUsuarios(): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/otrosusers')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }; 
  getSalidas(): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/tablerosalidas')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }; 
  getArribos(): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/tableroarribos')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }; 
  getServicios():Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/servicios')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }; 
  getVuelos(): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/tablero')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  };  
  
  getList(): Observable<OfertasResponse> {
    return this.httpClient
      .get<OfertasResponse>(this.SERVER_API_ADDRESS+'/traerOfertas')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }; 
  getLocales(): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/locales')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  };  
  getRecargas(): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/traerRecargas')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  };  

  getProductos(): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/getProducts')
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }; 
  getHorarios(id_local:any,fecha:any): Observable<any> {
    return this.httpClient
      .get<any>(this.SERVER_API_ADDRESS+'/traerhorariolocal/'+id_local+'?d='+fecha)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  };  
  
  checkIn(reservaid:any): Observable<any> {
       
    return this.httpClient.post(`${this.SERVER_API_ADDRESS}/checkIn`, {reservaid:reservaid }).pipe(
      tap(async (res: any) => {
         
      })
    );
   
} 
guardaVuelo(vuelo:any ):Observable<any> {
  return this.httpClient.post(`${this.SERVER_API_ADDRESS}/tablero/crea_vuelo`, vuelo).pipe(
    tap(async (res: any) => { 
       console.log(res);
       return res;
    
    })
  );
  
    
 }
 
 getOperadorColacionStatus(datos:any ):Observable<any> {
  return this.httpClient.post(`${this.SERVER_API_ADDRESS}/users/getcolacionstatus`, datos).pipe(
    tap(async (res: any) => { 
       
       return res;
    
    })
  );
  
    
 }
 getVuelosRep(datos:any ):Observable<any> {
  return this.httpClient.post(`${this.SERVER_API_ADDRESS}/tablero/consulta`, datos).pipe(
    tap(async (res: any) => {  
       return res; 
    })
  ); 
 }

 setEB(datos:any ):Observable<any> {
  return this.httpClient.post(`${this.SERVER_API_ADDRESS}/tablero/en_base`, datos).pipe(
    tap(async (res: any) => {  
       return res; 
    })
  ); 
 }
   setOperador(tablero:any ):Observable<any> {

    const idusuario = this.storage.get('ID_USUARIO');
    
    return from(idusuario).pipe(mergeMap(idusuario => { console.log(idusuario);
      tablero.idusuarioasigna=idusuario;
      return this.httpClient.post(`${this.SERVER_API_ADDRESS}/tablero/assign_sup`, tablero).pipe(
        tap(async (res: any) => { 
       
           return res; 
        })
      ); 
    }
    )) as any;
     }
     setColacion(tablero:any):Observable<any> {
      return this.httpClient.post(`${this.SERVER_API_ADDRESS}/tablero/envia_colacion`, tablero).pipe(
          tap(async (res: any) => {
             return res;
          })
        );
       }
       getObservaciones(vueloid:any):Observable<any> {
        return this.httpClient.post(`${this.SERVER_API_ADDRESS}/tablero/observaciones/obtener`, {vueloid}).pipe(
            tap(async (res: any) => {
               return res;
            })
        );
       }
       getEquipaje(vuelo:any):Observable<any> {
        return this.httpClient.post(`${this.SERVER_API_ADDRESS}/maletas/contar`, {vuelo}).pipe(
            tap(async (res: any) => {
               return res;
            })
        );
       }
         setObservacion(vuelo:any,observacion:any):Observable<any> { 
           
   
      return this.httpClient.post(`${this.SERVER_API_ADDRESS}/vuelo/observacionesadmin`, {vuelo,observacion}).pipe(
        tap(async (res: any) => {
           return res;
        })
      );
     
         
           }
     setServicio(tablero:any ):Observable<any> {
 
       
        return this.httpClient.post(`${this.SERVER_API_ADDRESS}/tablero/assign_ser`, tablero).pipe(
          tap(async (res: any) => { 
             console.log(res); 
             return res;
          
          })
        );
        
          
       }
  } 
 