import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from  './auth/auth.module';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiDataService } from './api/api-data.service' 

import { QRScanner } from '@ionic-native/qr-scanner/ngx'; 
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';  
   
import { LoaderInterceptor } from './services/loader.interceptor';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, AuthModule,HttpClientModule],
  providers: [
    StatusBar,ApiDataService,QRScanner,InAppBrowser,
    SplashScreen, 
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
